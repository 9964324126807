<template>
  <div class="layout_content">
    <el-container>
      <el-header>
        <div @click="home" style="cursor: pointer;">
          <img class="lay_logo" src="../../static/image/logo-min.png" />
          <img class="xclogo_min" src="../../static/image/xcLogo-min.png" />
        </div>
        
        <div class="menu">
          <span @click="home">首页</span>
          <span @click="page_(['StudentPlan','Plan'])" :class="`${['Plan','PlanDetail','StudentPlan','StudentPlanDetail'].includes(activePage)?'active':''}`">班级管理</span>
          <span @click="page_(['StudentWork','Work'])" :class="`${['Work','Worklist','Cyclework','Temporary','StudentWork','StudentWorklist'].includes(activePage)?'active':''}`">作业管理</span>
          <span @click="page_(['StudentStatistics','Statistics'])" :class="`${['Statistics','Statisticsdetail','StudentStatistics','StudentStatisticsdetail'].includes(activePage)?'active':''}`">成绩统计</span>
          <span v-if="config && config.stratify" @click="page_(['StudentStage','StageIndex'])" :class="`${['StudentStage','Stage','StageIndex'].includes(activePage)?'active':''}`">分层教学</span>
          <span v-if="config && config.question && userInfo.type == 0" @click="page_(['Questionbank'])" :class="`${['Questionbank'].includes(activePage)?'active':''}`">学校题库</span>
          <span v-if="userInfo.type == 0" @click="page_(['StudentMap'])" :class="`${['StudentMap','StudentMapDetail'].includes(activePage)?'active':''}`">学习计划</span>
          <span @click="page_(['StudentCollection','Collection'])" :class="`${['Collection','StudentCollection'].includes(activePage)?'active':''}`">我的收藏</span>
          <!-- <span @click="page_(['StudentShare','Share'])" :class="`${['Share','StudentShare'].includes(activePage)?'active':''}`">我的分享</span> -->
          <span @click="page_(['StudentUpdatepsd','Updatepsd'])" :class="`${['Updatepsd','StudentUpdatepsd'].includes(activePage)?'active':''}`">修改密码</span>
          
        </div>
        <div class="user_">

          <el-image fit="cover" :src="avatar"></el-image>

          <el-dropdown @command="loginOut">
            <span style="cursor: pointer;" class="el-dropdown-link">
              {{ userInfo.name?userInfo.name:"匿名" }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="cursor: pointer;">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside>
        
          <div class="left_uer_">
            <div @click="page_(['StudentInfo','Info'])" class="left_user_avatar">
              <el-image fit="cover" :src="avatar"></el-image>
              <div class="left_user_name">
                <span>{{ userInfo.name?userInfo.name:"匿名" }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="left_user_text">
              <span class="title">用户名</span>
              <span class="value">{{ userInfo.username }}</span>
            </div>
            <div class="left_user_text">
              <span class="title">学校</span>
              <span class="value">{{ userInfo.school_id_text }}</span>
            </div>
          </div>
          <div v-if="userInfo.type == 1" class="left_plan_info">
            <div class="left_plan_info_title">
              <img src="../../static/image/wdbj.png"/>
              <span>我的班级</span>
            </div>
            <Skeleton v-if="gradeloading" :skeleton_type="'grade'"></Skeleton>
            <div v-if="!gradeloading" class="container">
              <div class="item">
                <span class="item_name">班级数量</span>
                <span class="item_value">{{ info.plan_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">阅读篇数</span>
                <span class="item_value">{{ info.read_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">应试篇数</span>
                <span class="item_value">{{ info.specialty_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">学生人数</span>
                <span class="item_value">{{ info.student_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">阅读正确率</span>
                <span class="item_value">{{ info.read_rate }}</span>
              </div>
              <div class="item">
                <span class="item_name">应试正确率</span>
                <span class="item_value">{{ info.specialty_rate }}</span>
              </div>
            </div>
          </div>

          <div v-if="userInfo.type == 0" class="left_plan_info">
            <div class="left_plan_info_title">
              <img src="../../static/image/wdbj.png"/>
              <span>学习统计</span>
            </div>
            
            <Skeleton v-if="gradeloading" :skeleton_type="'grade'"></Skeleton>
            <div v-if="!gradeloading" class="container">
              <div class="item">
                <span class="item_name">阅读在线</span>
                <span class="item_value">{{ studentinfo.read_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">应试英语</span>
                <span class="item_value">{{ studentinfo.specialty_number }}</span>
              </div>
              <div class="item">
                <span class="item_name">阅读平均正确率</span>
                <span class="item_value">{{ studentinfo.read_rate }}</span>
              </div>
              <div class="item">
                <span class="item_name">应试平均正确率</span>
                <span class="item_value">{{ studentinfo.specialty_rate }}</span>
              </div>
              <div class="item">
                <span class="item_name">学习总时长</span>
                <span class="item_value">{{ studentinfo.total_time }}</span>
              </div>
              <div class="item">
                <span class="item_name">学习总积分</span>
                <span class="item_value">{{ studentinfo.integrallist }}</span>
              </div>
            </div>
          </div>

          <div class="left_message">
            <div class="left_plan_info_title">
              <img src="../../static/image/wdxx.png"/>
              <span>我的消息</span>
            </div>

            <Skeleton v-if="loading" :skeleton_type="'message'"></Skeleton>
            <div v-if="!loading">
            
              <el-empty v-if="!tidings_count" description="暂无消息"></el-empty>
              <ul v-if="studentTidings_data" class="message_list" style="padding-left: 0px;">
                <div v-for="(item,index) in studentTidings_data" :key="index"  @click="goWork(item.plan_id)" style="cursor: pointer;">
                  <el-card style="padding: 10px;margin-bottom: 15px;" shadow="hover">
                    <li class="left_message_list">
                      <span style="font-size: 14px;">{{ index+1 }}、 {{ item.body }}</span>
                      
                      <span style="float: right;font-size: 14px;opacity: 0.7;">{{ item.createtime }}</span>
                      
                    </li>
                  </el-card>
                </div>
                
              </ul>
              <el-button v-if="tidings_count > 3" style="display: inline-block;" size="small" @click="toMessage" type="text">查看更多</el-button>
            </div>
            
            
          </div>
          
        </el-aside>
        <el-main>
          <slot ></slot>
        </el-main>

      </el-container>
  </el-container>
    
  </div>
</template>

<script>
import Http from "../apis/user"
import Common from "../apis/common"
import skeleton from "../../untils/skeleton.vue";
export default {
  components:{
    'Skeleton':skeleton
  },
  name: 'MainLayout',
  data(){
    return{
      activePage:'',
      info:{},
      studentinfo:{},
      userInfo:{},
      studentTidings_data:[],
      tidings_count:0,
      config:{},
      loading:true,
      gradeloading:true,
      avatar:''
    }
  },
  activated(){
    
    
    this.activePage = this.$router.currentRoute.name
    

  },
  created(){
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"))

    if(this.userInfo.avatar && this.userInfo.avatar.includes('uploads')){
        this.userInfo.avatar = this.userInfo.avatar.replace('uploads', 'xichuanwh_readcos');
        this.avatar = `${this.$Server.Host}/${this.userInfo.avatar}`
    }else if(this.userInfo.avatar && !this.userInfo.avatar.includes('uploads')){
      if(this.userInfo.avatar.includes('data:image')){
          this.avatar = this.userInfo.avatar
      }else{
          this.avatar = this.$Config.baseUrl+this.userInfo.avatar
      }
    }
    if(this.userInfo.type == 1){
      this.getTeachInfo()
      this.teachTidings_()
    }else if(this.userInfo.type == 0){
      this.getStudentInfo()
      this.studentTidings_()
    }else if(this.userInfo.type == 3){
      this.openMessage()
    }
    this.Config()
  },

  mounted(){
    
  },
  methods:{
    set(e){
      
      this.userInfo.name = e.name
      this.avatar = this.$Config.baseUrl+e.avatar
      console.log(e)
    },
    goWork(plan_id){
      var params = {}
      params.plan_id = plan_id
      Common.getPlan(params).then((res) => {
        this.$router.push({name:'StudentWorklist',query:{plan_id:plan_id,plan_name:res.data.name}});
      });
      
    },

    toMessage(){
      if(this.userInfo.type == 1){
        this.$router.push({path:'TeachMessage'});
      }else{
        this.$router.push({path:'StudentMessage'});
      }
      
    },
    openMessage(){
      this.$alert('您目前属于游客状态，暂时使用功能限制，点击确定前往查看文章', '温馨提示', {
        confirmButtonText: '确定',
        callback: action => {

          //window.sessionStorage.setItem("expiryToas",1)

          this.$router.push({path:'/'});
        }
      });
    },
    open(str) {
      if(!window.sessionStorage.getItem("expiryToas")){
        this.$alert(str, '温馨提示', {
          confirmButtonText: '确定',
          callback: action => {

            window.sessionStorage.setItem("expiryToas",1)
            var path_name = this.userInfo.type ==0?'StudentInfo':'Info'

            this.$router.push({path:path_name});
          }
        });
      }
      
    },
    Config() {  
      
        Http.Config_().then((res) => {
          this.config = res.data.school
          if(this.userInfo.type == 0){
            if(!res.data.mobile){
              this.open('系统检测到您还未绑定手机号，绑定手机号后可以通过手机号修改密码或直接使用手机号登录，点击确定前往绑定。');
            }
            if(!res.data.stuid){
              this.open('系统检测到您还未绑定学号，不绑定学号可能会导致成绩异常，点击确定前往绑定。');
            }
            if(!res.data.is_school){
              this.open('系统检测到您还未绑定学校，绑定后才能加入班级，点击确定前往绑定。');
            }
          }
          
        });
      
    },

    page_(pageName){
      var type = this.userInfo.type
      var _this = this
      var name = pageName[type]
      _this.$router.push({name:name});
    },
    loginOut(){
      Http.Logout().then((res) => {
        if(res.code){
          this.userInfo = {}
          window.sessionStorage.clear()
          this.$router.push({path:'/Login'});
        }
        
      });
    },
    home(){
      this.$router.push({path:'/'});
    },
    // 老师个人中心统计
    getTeachInfo(){
      Http.planInfo().then((res) => {
        this.gradeloading = false
        this.loading = false
          this.info = res.data
          
      });

    },

    // 学生个人中心统计
    getStudentInfo(){
      Http.studentInfo().then((res) => {
        this.gradeloading = false
        this.studentinfo = res.data
      });

    },

    // 学生消息
    studentTidings_(){
      Http.studentTidings().then((res) => {
        this.loading = false
        this.studentTidings_data = res.data.data
        this.tidings_count = res.data.count
      });

    },
    // 老师消息
    teachTidings_(){
      Http.teachTidings().then((res) => {
        this.studentTidings_data = res.data.data
        this.tidings_count = res.data.count
      });
    }
  }
}
</script>
<style scoped>
@import url('../../static/css/layout.less');
@media screen and (min-width:320px) and (max-width:1199px) {
.layout_content >>> .el-aside{
  display: none !important;
} 
}
.layout_content >>> .el-header{
  padding: 0;
  padding-left: 3rem;
  height: 80px !important;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 99;
}
.layout_content >>> .el-aside{
  width: 23vw !important;
  margin-top: 20px;
  display: inline-block;
  margin-left: 1vw;
}
.user_ >>> .el-image{
  width: 45px;
  height: 45px;
  position: relative;
  top: 13px;
  border-radius: 50%;
}
.left_user_avatar >>> .el-image{
width: 60px;
height: 60px;
border-radius: 50px;
float: left;
}
.left_user_name{
cursor: pointer;
}
.left_user_name >>> .el-icon-arrow-right{
  font-size: 1.2vw;
  position: relative;
  top:3px;
}
.user_ >>> .el-dropdown{
top: -3px;
}
.menu span{
cursor: pointer;
}
.message_list >>> .el-card__body{
  padding: 0;
}
.left_message >>> .el-button{
  width: 100%;
}
</style>