<!-- 周期作业统计 -->
<template>
    <div class="second_box">
        <el-dialog title="作业统计" :visible.sync="dialogFormVisible" width="75%">

            <el-row :gutter="20">

                <p class="second_p_box"><el-button type="text" class="el-icon-s-data">成绩总览</el-button></p>

                <el-col :span="4">
                    <div>
                    <el-statistic title="完成情况（完成人数/班级人数）">
                        <template slot="formatter">
                            {{ statistics_data.all }}
                        </template>
                    </el-statistic>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                    <el-statistic title="阅读（完成人数/班级人数）">
                        <template slot="formatter">
                            {{ statistics_data.read }}
                        </template>
                    </el-statistic>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-statistic title="应试（完成人数/班级人数）">
                            <template slot="formatter">
                                {{ statistics_data.specialty }}
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-statistic title="阅读在线正确率">
                            <template slot="formatter">
                                {{ statistics_data.read_rate }}
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-statistic title="应试英语正确率">
                            <template slot="formatter">
                                {{ statistics_data.specialty_rate }}
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-statistic title="平均正确率">
                            <template slot="formatter">
                                {{ statistics_data.average_rate }}
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
            </el-row>
            <div class="label_button_box">
                <div class="label_button_box_left">
                    <el-input v-model="form.name" size="small" @input="changeStudent" :clearable="true" placeholder="请输入学生姓名"></el-input>
                    <!-- <el-button type="primary">搜索</el-button> -->
                </div>
                <div class="label_button_box_right">
                    
                    <el-button size="small" @click="importTotalFile" type="primary">导出成绩</el-button>
                    <!-- <el-button size="small" type="primary" plain style="margin-right: 10px;">重置成绩</el-button> -->
                </div>
                <p class="colm_box">
                    <el-checkbox-group v-model="checkList">
                        <el-checkbox v-for="(item,index) in file_form" :disabled="isDisabled(index)" :key="index" :label="index">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </p>
            </div>
            <el-table
                :data="student"
                tooltip-effect="dark"
                style="width: 100%;display: inline-block;margin-top: 30px;"
                >
                <el-table-column
                type="index"
                width="55">
                </el-table-column>
                <el-table-column
                prop="name"
                label="姓名"
                >
                </el-table-column>
                <el-table-column
                prop="stuid"
                label="学号"
                >
                </el-table-column>
                <el-table-column
                prop="read_number"
                label="阅读在线"
                >
                </el-table-column>
                <el-table-column
                prop="read_rate"
                label="正确率"
                >
                </el-table-column>
                <el-table-column
                prop="specialty_number"
                label="应试英语"
                >
                </el-table-column>
                <el-table-column
                prop="specialty_rate"
                label="正确率"
                >
                </el-table-column>
                <el-table-column
                prop="fraction"
                label="得分"
                >
                </el-table-column>

                <el-table-column
                prop="study_time"
                label="做题时长"
                >
                </el-table-column>
            </el-table>
        
        </el-dialog>
        
    </div>
</template>
<script>
import Http from "../src/apis/statistics";
export default {
    data(){
        return{
            dialogFormVisible:false,
            checkList:[],
            file_form:[],
            tableData:[],
            form:{
                name:'',
                plan_id:'',
                task_id:'',
                type:1
            },
            plan_id:'',
            task_id:'',
            statistics_data:{},
            student:[],
            checDisList:[],
        }
    },

    methods:{
        isDisabled(index) {
            var checDisList = JSON.parse(JSON.stringify(this.checDisList))

            for(var i in checDisList){

                if(index == checDisList[i]){
                    return true
                }
            }

        },
        setData(file_form,checkList,state){
            this.form.state = state
            this.checkList = checkList
            this.checDisList = checkList
            this.file_form = file_form
        },

        // 下方学生列表
        getBottomStudent(){
        
            Http.Student(this.form).then((res) => {
                this.student = res.data
                this.$emit('hideLoading',false)
            });
        },
        changeStudent(){
            this.getBottomStudent()
        },
        // 顶部
        viewWorkDetail(plan_id,task_id){

            this.form.plan_id = plan_id
            this.form.task_id = task_id
            var params = {}
            params.plan_id = plan_id
            params.task_id = task_id
            params.state = this.form.state
            Http.Cycle(params).then((res) => {
                this.getBottomStudent()
                this.statistics_data = res.data
                this.dialogFormVisible = true
            });
            
        },
        importTotalFile(){
            // 文件的直接下载链接  

            var params = this.checkList
            var condition = {}
            for(var index in params){
                condition[params[index]] = true
            }
            condition.plan_id = this.form.plan_id
            condition.type = 1
            condition.task_id = this.form.task_id
            const fileUrl = `${this.$Config.baseUrl}/api/teachgrades/import?token=${window.sessionStorage.getItem("token")}&${this.$Common.objectToQueryString(condition)}`;  

            location.href = fileUrl
        }
    }
}
</script>
<style scoped>
.box-card{
    padding: 10px 35px;
    margin-top: 20px;
    margin-right: 15px;
}
.box-card  >>> .el-card__body{

    padding: 0;
}
.box-card .item{
    line-height: 30px;

}

.box-card .number{
    font-weight: 700;
    color: #409EFF;
}
.box-card >>> .name{
    font-weight: 0 !important;
    font-size: 14px;
    font-weight: 700;
}
.box-card span{
    width: auto;
}
.second_box {
    text-align: left;
}

.second_box >>> .el-select{
    width: 25vw;
}
.second_box >>> .el-row{

    margin-top: 15px !important;
    padding: 10px 0;
}
.second_box >>> .title{
 
    line-height: 30px;
 
}
.second_box >>> .number{
 
    line-height: 30px;
    color: #409EFF;
}

.second_box >>> .head{
 
 color: #000000;
}
.second_p_box >>> .el-button--text{

    font-size: 18px;
}
.second_p_box{
    text-indent: 2vw;
    margin: 0 !important;
    padding: 0 !important;
}
.label_button_box{
    width: 100%;
    height: 2vw;
    line-height: 2vw;
    display: inline-block;
    margin-bottom: 20px;
    margin-top:20px;
}
.label_button_box_left{
    float: left;
}
.label_button_box_right{
    float: right;

}
.label_button_box_right >>> .el-button{
        float: right;
    }
.label_button_box_left >>> .el-input{
    float: left;
    width: 15vw;
    margin-right: 10px;
}
.colm_box{
    display: inline-block;
    text-align: right;
    height: 2vw;
    margin-bottom: 20px;
    border: 1px solid rgb(235, 238, 245);
    padding: 0px 20px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}
</style>