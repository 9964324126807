import axios from '../services/request'

/* 获取题库分类 */
export const getBanktype = (data = {}) => axios.get('api/questionbank/getTypes',data);

/* 获取题库 */
export const getBankList = (data = {}) => axios.get('api/questionbank/getQuestions',data);

/* 获取试题 */
export const getQuestionTest = (data = {}) => axios.get('api/questionbank/getQuestionTest',data);

/* 提交答案 */
export const submitBank = (data = {}) => axios.post('api/questionbank/submit',data);

export default{
    getBanktype,
    getBankList,
    getQuestionTest,
    submitBank
}