<!-- 阅读题类型 -->
<template>
    <div>
        <div class="question_box">
            <div class="question_left">
            
                <el-tabs v-model="readname">
                    <el-tab-pane label="阅读原文" name="first">
                        <div class="declare_box">
                            <div class="declare_table">Directions:</div>
                            <div class="declare" v-html="read.description"></div>
                        </div>
                        
                        <div style="text-align: left;font-size: 15px;line-height: 25px;text-indent: 25px;" v-html="read.body"></div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="question_right">
                <el-tabs v-model="activeName1">
                    <el-tab-pane label="测试题目" name="first">
                        <div>
                            <div class="first_question" v-for="(item,index) in readtest" :key="index">
                                <span class="question">{{ index+1 }}. {{ item.question }}</span>
                                <div style="cursor: pointer;"> 
                                    <el-col v-for="(value,k) in item.answer" :key="k" :span="22" >
                                        <!-- 选择题 -->
                                        <el-card :class="value.u_submit?(value.name == value.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.name?'right':'')" shadow="hover">
                                            <div @click="optionsQuestion(item.id,value)">
                                                <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                            </div>
                                        </el-card>
                                        <!-- 判断题 -->
                                        <el-card v-if="read.r_type == 2" :class="value.u_submit?(value.value == value.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.value?'right':'')" shadow="hover">
                                            <div @click="optionsQuestion(item.id,value)">
                                                <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                            </div>
                                        </el-card>
                                    </el-col>
                                </div>
         
                                
                            </div>
                    
                            
                            <el-button v-if="!submitanswers_button" type="primary" @click="submit" size="small">确认提交</el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="答案解析" name="second">
                    
                        <el-empty v-if="!submitanswers_button" description="请先做题"></el-empty>
                        <div v-if="submitanswers_button" class="first_question">
                            <el-col v-for="(item,index) in decipher" :key="index" :span="22">
                                <el-card shadow="never">
                                    <span style="font-weight: 700;">{{ index+1 }}、</span>{{ item }}
                                </el-card>
                            </el-col>
                        </div>
                        
                    </el-tab-pane>

                </el-tabs>
            </div>
        </div>
        
    </div>
</template>
<script>
import Http from "../../src/apis/questionbank";
export default {
    props:{
        read:{
            type:Object,
            value:{}
        },
        in_time:{
            type:Number,
            value:0
        }
    },
    data(){
        return{
            activeName1:'first',
            readname:'first',
            readtest:[],
            submitanswers_button:false,
            decipher:[],
            fill:{},
            options:{},
            
        }
    },

    methods:{

        getReadTest(){
            console.log(11)
            var params = {}
            params.type = this.read.type
            params.qid = this.read.id
            Http.getQuestionTest(params).then((res) => {
                this.decipher = res.data.decipher
                this.readtest = res.data.data
                this.submitanswers_button = res.data.submitanswer?true:false
            })
        },
        optionsQuestion(id,option){
            var params = JSON.parse(JSON.stringify(this.options))
 
            params[id] = option.name
  
            this.options = params
        },

        submit(){
            var params = {}
            params.body = this.options
            params.wid = this.read.id
            params.in_time = this.in_time
            if(Object.keys(this.options).length < this.readtest.length){
                this.$message({
                    showClose: true,
                    message: '答案提交不完整',
                    type: 'error'
                });
                return false;
            }
            Http.submitBank(params).then((res) => {

                if(res.code){
                
                    this.$alert(res.msg, '提示', {
                        confirmButtonText: '确定'
                    });
                    this.getReadTest()
                }

            })
        }
    }
}
</script>
<style scoped>
.question_box{

width: 100%;
height: 800px;
}
.question_left{
width: 50%;
float: left;
height: 800px;
overflow-y: auto;
box-sizing: border-box;
}
.question_left::-webkit-scrollbar {  
width: 10px; /* 滚动条的宽度 */  
}  

.question_left::-webkit-scrollbar-track {  
background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  

.question_left::-webkit-scrollbar-thumb {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
border-radius: 10px; /* 圆角效果 */  
}  

.question_left::-webkit-scrollbar-thumb:hover {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
position: sticky;
background: #ffffff;
top: 0;
z-index: 99;
}
.question_left >>> .el-tabs__content{

padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
    width: 10px; /* 滚动条的宽度 */  
}  

.question_right::-webkit-scrollbar-track {  
background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  

.question_right::-webkit-scrollbar-thumb {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
border-radius: 10px; /* 圆角效果 */  
}  

.question_right::-webkit-scrollbar-thumb:hover {  
background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question .question{
font-size: 16px;
font-weight: 700;
color: #000000;
line-height: 23px;
display: inline-block;
}
.right{
background: #75A3E4 !important;
color: #ffffff;
box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

}
.error{
background: #FF6E6E !important;
color: #ffffff;
box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.first_question {
padding: 20px;
width: 95%;
display: inline-block;
text-align: left;
margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
min-height: 150px !important;
}
.first_question >>> .el-card__body{
padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.declare_box{
    background: #F6F7FF;
    font-size: 14px;
    padding: 10px;
    border-radius: 7px;
}
.declare_table{
    text-align: left;
    font-weight: 700;
    margin-bottom: 10px;
}
.declare{
    display: inline-block;
    
    text-align: left;
    line-height: 27px;
    text-indent: 25px;
}
</style>