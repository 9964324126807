<template>
    <div class="index_page">
        <layoutpage>
            <div class="content">
                <div class="top_box">
                    <div class="banner_box">
                        <el-carousel indicator-position="none" :interval="5000">
                            <el-carousel-item v-for="(item,index) in banner_data" :key="index">
                                <img :src="item.image" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    
                    <!-- <img :src="banner_data.image" /> -->
                    <div v-if="!userInfo" class="top_right"> 
                        <img src="../../../static/image/tx-da.png" />
                        <span class="">一起遨游英语学习的海洋吧</span>
                        <el-button type="primary" size="mini" round @click="login">登录</el-button>
                        <span class="forpwd">忘记密码？</span>
                        <span class="">立即注册</span>
                    </div>
                    <div v-if="userInfo" class="top_right">
                        <img v-if="Object.keys(userInfo).length === 0" src="../../../static/image/txzhong.png" />
                        <img style="object-fit: cover;" v-if="userInfo && Object.keys(userInfo).length" :src="avatar" />

                        <span class="">一起遨游英语学习的海洋吧</span>
                        <span style="font-size: 18px;">{{ userInfo.name }}</span>
                        <span v-if="Object.keys(userInfo).length !== 0" class="forpwd loginout" style="font-size: 14px !important;" @click="loginOut">退出登录</span>
                        <span v-if="Object.keys(userInfo).length === 0" class="forpwd loginout" style="font-size: 14px !important;" @click="login">前往登录</span>
                    </div>
                </div>
                <div class="proverb"> 
                    <img class="proverb_icon" src="../../../static/image/mryy.png" />
                    <span class="proverb_title">每日谚语</span>
                    <span class="proverb_content">{{ everyday.content }}</span>
                    <router-link to="/everydayIndex">
                        <i class="el-icon-arrow-right" style="color: #000000;"></i>
                    </router-link>
                    
                </div>
                <div class="label_name">
                    <img class="label_name_icon" src="../../../static/image/yuedu.png" />
                    <router-link to="/readIndex">
                        <div style="cursor: pointer;">
                            <img class="more_icon" src="../../../static/image/gengduo.png" />
                            <span class="more">查看更多</span>
                        </div>
                    </router-link>
                </div>
                
                <div class="flex-container">
                    <Skeleton v-if="loading" :skeleton_type="'read_index'"></Skeleton>
                    <div v-for="(item,index) in read" :key="index" class="item" @click="readDetail(item.id)">
                        <div class="time_icon_box">
                            <img src="../../../static/image/shijiany_icon.png" />
                        </div>
                        <div class="time_line_box">
                            <div class="time_left"><span style="font-size: 18px;">{{ item.day }}</span> <span style="line-height: 22px;color: #999999;margin-left: 5px;font-size: 14px;">{{ item.time }}</span></div>
                            <div class="time_right">
                                <div>x {{ item.grade }}</div>
                                <img src="../../../static/image/xingxing.png" />
                            </div>
                            <img class="time_thumb" :src="item.image" />
                            <span class="read_title">{{ item.title }}</span>
                        </div>
                    </div>
                </div>

                <div class="label_name">
                    <img class="label_name_icon" src="../../../static/image/yingshi.png" />
                    <router-link to="/SpecialtyIndex">
                        <div style="cursor: pointer;">
                            <img class="more_icon" src="../../../static/image/gengduo.png" />
                            <span class="more">查看更多</span>
                        </div>
                    </router-link>
                    
                </div>

                <div class="spty_box">
                    <div class="sp_left">
                        <div class="sp_left_title">
                            <img src="../../../static/image/tjfx.png" />
                            <span>相关推荐</span>
                        </div>
                        <Skeleton v-if="loading" :skeleton_type="'specialtyIndex'"></Skeleton>
                        <div v-for="(item,index) in specialty" :key="index" class="infinite-list-item" @click="handleRowClick(item.id,item.type)">
                            <div class="list-item-title">
                                <span :class="`serialnumber ${(index+1) == 1?'one':(index+1==2?'two':(index+1==3?'three':''))}`">{{ index+1 }}</span><span>{{ item.title }}</span> 
                            </div>
                            <span class="list-item-right">{{ item.click }}</span>
                        </div>
       
                    </div>
                    <div class="sp_right"> 
                        <div class="list" style="margin-top: 15px;">
                            <router-link to="/SpecialtyIndex?select=0">
                                <div class="list-item">
                                    <img src="../../../static/image/chujiyingyu.png" />
                                </div>
                            </router-link>
                            <router-link to="/SpecialtyIndex?select=1">
                                <div class="list-item">
                                    <img src="../../../static/image/ABjiyingyu.png" />
                                </div>
                            </router-link>
                            <router-link to="/SpecialtyIndex?select=2">
                                <div class="list-item">
                                    <img src="../../../static/image/zhuanshengben.png" />
                                </div>
                            </router-link>
                            
                        </div>
                        <div class="list" style="margin-top: 10px;">
                            <router-link to="/SpecialtyIndex?select=3">
                                <div class="list-item">
                                    <img src="../../../static/image/siliuji.png" />
                                </div>
                            </router-link>
                            <router-link to="/SpecialtyIndex?select=4">
                                <div class="list-item">
                                    <img src="../../../static/image/zhuanyeyingyu.png" />
                                </div>
                            </router-link>
                            <router-link to="/SpecialtyIndex?select=5">
                                <div class="list-item">
                                    <img src="../../../static/image/kaoyanyingyu.png" />
                                </div>
                            </router-link>
                            
                        </div>
                    </div>
                </div>
                <div class="label_name">
                    <img class="label_name_icon" src="../../../static/image/whsx.png" />
                    <router-link to="/cultureIndex">
                        <div style="cursor: pointer;">
                            <img class="more_icon" src="../../../static/image/gengduo.png" />
                            <span class="more">查看更多</span>
                        </div>
                    </router-link>
                    
                </div>
                <div class="appreciation">
                    <div class="appreciation-item hot">
           
                        <div class="sp_left_title appreciation-item-title">
                            <img style="margin-top: 4px;" src="../../../static/image/paihang.png" />
                            <span>热门排行</span>
                        </div>
                        <div @click="Culture(item.id)" v-for="(item,index) in culture.hot" :key="index" class="appreciation-list">
                            <img :src="item.image" />
                            <div class="appreciation-text-box">   
                                <span class="appreciation-text-title">{{ item.title }}</span>
                                <span class="appreciation-type">【{{ item.type_text }}】</span>
                                <span class="appreciation-number">{{ item.click }} 观看</span>
                            </div>
                        </div>
             
                    </div>
                    <div class="appreciation-item new">
                        <div class="sp_left_title appreciation-two-title">
                            <img style="margin-top: 4px;" src="../../../static/image/gengxin.png" />
                            <span>最近更新</span>
                        </div>
                        <div @click="Culture(item.id)" v-for="(item,index) in culture.recently" :key="index" class="appreciation-list">
                            <img :src="item.image" />
                            <div class="appreciation-text-box">   
                                <span class="appreciation-text-title">{{ item.title }}</span>
                                <span class="appreciation-type">【{{ item.type_text }}】</span>
                                <span class="appreciation-number">{{ item.click }} 观看</span>
                            </div>
                        </div>
                    </div>
                    <div class="appreciation-item" style="padding: 0;">
                        <router-link to="/cultureIndex?select=2">
                            <div class="renwenfengsu">
                                <span class="renwenfengsu_name">风俗</span>
                                <span class="renwenfengsu_number">累计 {{ cultureNumber.fengsu }} 篇</span>
                            </div>
                        </router-link>
                        <router-link to="/cultureIndex?select=3">
                            <div class="jiaoyubank">
                                <span class="renwenfengsu_name">教育</span>
                                <span class="renwenfengsu_number">累计 {{ cultureNumber.jiaoyu }} 篇</span>
                            </div>
                        </router-link>
                        <router-link to="/cultureIndex?select=1">
                            <div class="lishi">
                                <span class="renwenfengsu_name">历史</span>
                                <span class="renwenfengsu_number">累计 {{ cultureNumber.lishi }} 篇</span>
                            </div>
                        </router-link>
                        <router-link to="/cultureIndex?select=5">
                            <div class="zhengzhi">
                                <span class="renwenfengsu_name">政治</span>
                                <span class="renwenfengsu_number">累计 {{ cultureNumber.zhengzhi }} 篇</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="label_name">
                    <img class="label_name_icon" src="../../../static/image/zyxz.png" />
                    <router-link to="/downIndex">
                        <div style="cursor: pointer;">
                            <img class="more_icon" src="../../../static/image/gengduo.png" />
                            <span class="more">查看更多</span>
                        </div>
                    </router-link>
                    
                </div>
                <div class="down">
                    <div class="down-container">
                        <div v-for="(item,index) in down" :key="index" style="border-radius: 7px;">
                            <i class="el-icon-s-shop"></i>
                            <div class="down-text">
                                <span class="down-text-title">{{ item.title }}</span>
                                <div class="down-options">
                                    <!-- <img src="../../../static/image/fenxiangziyuan.png" />
                                    <span>教师分享</span> -->
                                    <img src="../../../static/image/shoucangziy.png" />
                                    <span>点击收藏</span>
                                </div>
                                <div class="down-options">
                                    <span>{{ item.detail }}</span>
                                </div>
                            </div>
                            <el-button @click="downFile(item)" size="mini" plain>前往下载</el-button>
                        </div>
                    </div>
                </div>
                <div class="label_name">
                    <img class="label_name_icon" src="../../../static/image/hezuogaoxiao.png" />
                    <span style="display: inline-block;margin-top: 2px;margin-left: 8px;">(排名不分先后)</span>
                    <!-- <div style="cursor: pointer;">
                        <img class="more_icon" src="../../../static/image/gengduo.png" />
                        <span class="more">查看更多</span>
                    </div> -->
                </div>

                <div class="school">
                    <div class="school-container">
                        <div v-for="(item,index) in cooperation" :key="index" class="school-item">
                            <img :src="item.image"/>
                        </div>
 
                    </div>

                </div>

            </div>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/index";
import User from "../../apis/user"
import skeleton from "../../../untils/skeleton.vue";
import { param } from "jquery";
export default {
    components:{
        'Skeleton':skeleton
    },
    data(){
        return {
            everyday:{},
            read:[],
            specialty:[],
            culture:{},
            down:[],
            cooperation:[],
            loading:true,
            userInfo:{},
            banner_data:[],
            cultureNumber:{},
            avatar:''
        }
    },

    mounted() {
        
        this.getBanner_()
        this.getIndex()
    },
    activated(){
        window.addEventListener('popstate', this.handlePopState());  
        var userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));

        if(userInfo){
           
            if(userInfo.avatar && userInfo.avatar.includes('uploads')){
                
                userInfo.avatar = userInfo.avatar.replace('uploads', 'xichuanwh_readcos');
                this.avatar = `${this.$Server.Host}/${userInfo.avatar}`
            }else if(userInfo.avatar && !userInfo.avatar.includes('uploads')){
                if(userInfo.avatar.includes('data:image')){
                    this.avatar = userInfo.avatar
                }else{
                    this.avatar = this.$Config.baseUrl+userInfo.avatar
                }
            }
        }else{
            userInfo = {}
        }
        
        
        
        this.userInfo = userInfo
    },

    methods:{
        getBanner_(){
            var params = {}
            params.type = "pc"
            Http.getBanner(params).then((res) => {
                this.$Common.reconsitution(res.data,'plan_list')
                this.banner_data = res.data
            });
        },
        login(){
            this.$router.push({name:'Login'})
        },
        handlePopState(){
            document.body.style.overflow = 'auto';
        },
        getIndex(){
            Http.Index().then((res) => {
                this.$Common.reconsitution(res.data.read,'read')
                this.$Common.reconsitution(res.data.cooperation,'read')
                this.$Common.reconsitution(res.data.culture,'culture')
                this.everyday   = res.data.everyday
                this.read       = res.data.read
                this.specialty  = res.data.specialty
                this.culture    = res.data.culture
                this.down       = res.data.down
                this.cooperation= res.data.cooperation
                this.cultureNumber = res.data.cultureNumber
                this.loading    = false
            });
        },
        readDetail(id){
            if(!window.sessionStorage.getItem("userinfo")){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false
            }
            this.$router.push({name:'ReadDetail',query:{id:id,type:0}});
        },
        handleRowClick(id,type){
            var _this = this
            if(!window.sessionStorage.getItem("userinfo")){

                _this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false
            }
            _this.$router.push({name:'SpecialtyDetail',query:{id:id,type:type}});
        },
        loginOut(){
            User.Logout().then((res) => {
                if(res.code){
                    this.userInfo = {}
                    window.sessionStorage.clear()
                    this.$router.push({path:'/Login'});
                }
            });
        },

        Culture(id){
            this.$router.push({name:'CultureIndex',query:{id:id}});
        },
        downFile(row){
            if(row.baidu_url){
                window.open(row.baidu_url, '_blank');
            }else{
 
                window.open(`${this.$Server.Host}${row.down_url}`, '_blank');
            }
        }

    }
}
</script>
<style scoped>
.loginout{
    cursor: pointer;
}
.content{
    width: 1200px;
    margin: 0 auto;
}
.top_box{
    width: 100%;
    height: 360px;
    display: inline-block;
    margin-top: 15px;
}
.top_box .banner_box{
    width: 992px;
    height: 360px;
    border-radius: 7px;
    float: left;
}
.banner_box >>> .el-carousel{
    border-radius: 20px;
}
.banner_box img{
    width: 100%;
}
.banner_box >>> .el-carousel__container {
    height: 360px;
    border-radius: 10px;
}
.top_box .top_right{
    width: 200px;
    height: 360px;
    background: #ffffff;
    float: right;
    border-radius: 7px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 80px;
}
.top_right img{
    width: 70px;
    height: 70px;
    border: 50px;
    display: inline-block;
    margin-left: 2px;
    border-radius: 50px;
}
.top_right span{
    width: 130px;
    margin: 0 auto;
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
}
.top_right >>> .el-button{
    width: 160px;
    margin-top: 15px;
}
.forpwd{
    font-size: 12px !important;
    color: #999999;
}
.proverb {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #ffffff;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
}
.proverb_icon{
    float: left;
    width: 25px;
    height: 25px;
    margin: 17.5px;
}
.proverb_title{
    font-size: 18px;
    font-weight: 700;
    float: left;
    margin-top: 2px;
}
.proverb_content{
    font-size: 18px;
    color: #282828;
    float: left;
    margin-left: 25px;
    margin-top: 3px;
}
.proverb >>> .el-icon-arrow-right{
    font-size: 24px;
    float: right;
    margin-right: 25px;
    margin-top: 18px;
    cursor: pointer;
}
.label_name{
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-top: 20px;
}
.more_icon{
    float: right;
    margin-left: 10px;
    margin-top: 3px;
}
.more{
    float: right;
    font-size: 12px;
    color: #999999;
}
.label_name_icon{
    float: left;
}
.content >>> .el-step__head{
    text-align: left;
}
.flex-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}
.item {
    flex: 0 0 16.666%;
    text-align: center;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-top: 3px #ffffff solid;
    cursor: pointer;
}
.time_icon_box{
    position: relative;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 20px;
    margin-top: -16px;
    margin-left: -13px;
}
.time_line_box{
    width: calc(100% - 25px);
    margin-top: 10px;
}
.time_right{
    float: right;
    margin-top: -1px;
}
.time_left{
    float: left;
}
.time_left span{
    float: left;
    width: 10px;
    width: 46px;
}
.time_right div{
    float: right;
    margin-top: 3px;
    margin-left: 5px;
    color: #FF934A;
}
.time_right img{
    float: right;
}
.time_thumb{
    width: 100%;
    height: 120px;
    border-radius: 6px;
    margin-top: 15px;
}
.read_title{
    font-size: 14px;
    color: #282828;
    margin-top: 5px;
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 2;  
    overflow: hidden;  
}
.spty_box{
    width: 100%;
    display: inline-block;
    margin-top: 15px;
}
.sp_left{
    float: left;
    width: 390px;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
}
.sp_left_title{
    display: inline-block;
    width: 100%;
    text-align: left;
    line-height: 30px;
    margin-bottom: 15px;
}
.sp_left_title img{
    float: left;
    margin-top: 7px;
    margin-right: 5px;
}
.sp_left_title span{
    font-weight: 700;
    color: #2492d6;
    font-size: 18px;
}
.infinite-list-item{
    width: 100%;
    display: inline-block;
    text-align: left;
    line-height: 30px;
    cursor: pointer;
}
.list-item-title{
    width: 80%;
    float: left;
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 1;  
    overflow: hidden;  
    font-size: 14px;
    
}
.list-item-title span{
    color:#282828;
}
.list-item-right{
    float: right;
    font-size: 14px;
}
.one{
    font-size: 16px !important;
    font-weight: 700;
    color: #dc6262 !important;
}
.two{
    font-size: 16px !important;
    font-weight: 700;
    color: #ec7d46 !important;
}
.three{
    font-size: 16px !important;
    font-weight: 700;
    color: #ff9a18 !important;
}
.serialnumber{
    margin-right: 5px;
    font-weight: 700;
}

.sp_right{
    width: 750px;
    float: right;
}
.list {
    display: flex;
    justify-content: space-between; /* 每个项目平分剩余空间 */
}

.list-item {
    flex: 0 0 calc(33.33% - 10px); /* 计算每个项目的宽度，考虑到间距，这里假设10px的间距 */
    margin-right: 15px; /* 设置项目之间的右侧间距 */
    text-align: center; /* 居中文本 */
    background-color: #f0f0f0; /* 设置背景色 */
    box-sizing: border-box; /* 让内边距和边框不会增加元素的实际尺寸 */
}

.list-item:last-child {
    margin-right: 0; /* 最后一个项目取消右侧间距，避免多余的间距 */
}
.list-item img{
    width: 100%;
    cursor: pointer;
}

.appreciation {
    display: flex;
    margin-top: 20px;
}

.appreciation-item {
    flex: 1;
    margin: 0 10px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 7px;
    
}
.hot{
    background:url('../../../static/image/rmph.png');
    height: 433px;
}
.new{
    background:url('../../../static/image/zjgx.png');
    height: 433px;
}
.appreciation-item:first-child {
    margin-left: 0;
}

.appreciation-item:last-child {
    margin-right: 0;
}
.appreciation-item-title span{
    color: #dc6262 !important;
}
.appreciation-two-title span{
    color: #e98f46 !important;
}
.appreciation-list{
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
}
.appreciation-list img{
    float: left;
    width: 110px;
    height: 72px;
    border-radius: 6px;
}
.appreciation-text-box{
    float: left;
    width: 220px;
    height: 72px;
    margin-left: 10px;

}
.appreciation-text-title{
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 1;  
    overflow: hidden;  
    font-size: 14px;
    color: #282828;
}
.appreciation-type{
    width: 220px;
    display: inline-block;
    text-align: left;
    font-size: 12px;
    color: #999999;
    margin-top: 8px;
    text-indent: -4px;
}
.appreciation-number{
    width: 220px;
    display: inline-block;
    text-align: left;
    font-size: 12px;
    color: #999999;
    margin-top: 8px;
}
.renwenfengsu{
    background: url('../../../static/image/renwenfengsu.png');
    width: 385px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.renwenfengsu_name{
    font-size: 20px;
    font-weight: 700;
    color: #282828;
    width: 100%;
    display: inline-block;
    margin-top: 27px;
    text-align: left;
    margin-left: 118px;
}
.renwenfengsu_number{
    font-size: 14px;
    color: #999999;
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-left: 118px;
    margin-top: 5px;
}
.jiaoyubank{
    background: url('../../../static/image/jiaoyubank.png');
    width: 385px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.lishi{
    background: url('../../../static/image/lishi.png');
    width: 385px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.zhengzhi{
    background: url('../../../static/image/zhengzhi.png');
    width: 385px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.down{
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.down-container {
    display: flex; /* 使用Flex布局 */
    flex-wrap: wrap; /* 允许子元素换行 */
    justify-content: space-between; /* 每行两个div，剩余空间平分 */
    
}

.down-container > div {
    flex-basis: calc(50% - 10px); /* 每个子div宽度为50%，减去间距 */
    margin-bottom: 20px; /* 底部间距，可以根据需要调整 */
    background-color: #ffffff; /* 设置背景色 */
    padding: 15px; /* 设置内边距 */
    box-sizing: border-box; /* 让内边距和边框不会增加元素的实际尺寸 */
}

.down-container > div:nth-child(odd) {
    margin-right: 0px; /* 第一列右侧间距，可以根据需要调整 */
}

.down-container > div:nth-child(even) {
    margin-left: 20px; /* 第二列左侧间距，可以根据需要调整 */
}
.down-container >>> i{
    font-size: 28px;
    color: #2492d6;
    float: left;
    margin-left: 30px;
    line-height: 70px;
    margin-right: 20px;
}
.down-text {
    height: 70px;
    width: 300px;
    float: left;
    padding-left: 10px;
}
.down-text-title{
    font-size: 16px;
    color: #282828;
    width: 100%;
    display: inline-block;
    text-align: left;
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 1;  
    overflow: hidden;  
}
.down-options{
    width: 100%;
    font-size: 12px;
    color: #999999;
    display: inline-block;
    margin-top: 7px;
    text-align: left;
}
.down-options img{
    float: left;
    margin-right: 5px;
}
.down-options span{
    float: left;
    margin-right: 15px;
}
.down-container >>> .el-button{
    position: relative;
    margin-top: 22px;
}
.school{
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}
.school-container {
    display: flex;
    flex-wrap: wrap;
}

.school-item {
    flex: 0 0 calc(19% - 4px);
    margin: 8px;
}
.school-item img{
    width: 100%;
    height: 68px;
    border-radius: 6px;
}
</style>