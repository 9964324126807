<template>
    <div class="page">
        <layout>
            <div class="content">
                <div class="screen">
                    <el-row>
                        <el-button size="small" disabled type="text">题型</el-button>
                        <span v-for="(labelitem,labelindex) in label" :class="`${labelitem.select?'select':''}`" @click="selectLabel(labelitem.id)" :key="labelindex">{{ labelitem.name }}</span>

                    </el-row>
                </div>
                <el-table
                    :data="tableData_"
                    :cell-style="{cursor: 'pointer'}"
                    @row-click="handleRowClick"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="title"
                    label="标题"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="submit_text"
                    label="状态"
                    width="100"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="createtime_text"
                    label="发布时间"
                    width="100"
                    >
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total_number > form_data.pageSize"
                    style="margin-top: 20px;"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="form_data.pageSize"
                    :total="total_number">
                </el-pagination>
            </div>

            <el-dialog width="65%" :title="questiontitle" @close="closeDialog" :visible.sync="dialogFormVisible">
                <bankRead v-if="read.type == 1" ref="bankRead_" :in_time="in_time" :read="read"></bankRead>
                <bankChoice v-if="read.type != 1" ref="bankChoice_" :in_time="in_time" :read="read"></bankChoice>
            </el-dialog>

        </layout>
    </div>

</template>
<script>
import Http from "../../apis/questionbank";
import read from "../../../untils/questionbank/read";
import choice from "../../../untils/questionbank/choice";
export default {
    components:{
        'bankRead':read,
        'bankChoice':choice,
    },
    data(){
        return{
            dialogFormVisible:false,
            label:{},
            total_number:0,
            form_data:{
                page:1,
                pageSize:20,
                type:''
            },
            in_time:0,
            read:{},
            questiontitle:'',
            tableData_:[]
        }
    },
    activated(){
        this.getBanktype_()
        this.in_time = Math.floor(Date.now() / 1000)
    },

    methods:{

        getBanktype_(){
            Http.getBanktype().then((res) => {

                var label = []
                var type = ''
                for(let index in res.data){
                    let result = {}
                    result.id = res.data[index].id
                    result.name = res.data[index].name
                    result.select = index == 0?true:false
                    if(index == 0){
                        type = res.data[index].id
                    }
                    label.push(result)
                }
                this.form_data.type = type
                this.label = label
                this.getBankList_()
            });
        },
        getBankList_(){
            Http.getBankList(this.form_data).then((res) => {
                this.tableData_ = res.data.data
                this.total_number = res.data.total_number

            })
        },
        selectLabel(id){
            var label = JSON.parse(JSON.stringify(this.label))
            for(var i in label){
                if(id == label[i].id){
                    label[i].select = true
                }else{
                    label[i].select = false
                }
            }
            this.form_data.type = id
            this.label = label

            this.getBankList_()
        },
        handleRowClick(row,event){
            this.dialogFormVisible = true
            this.questiontitle = row.title
            this.read = row
            setTimeout(()=>{
                if(row.type == 1){
                    this.$refs.bankRead_.getReadTest()
                }else{
                    this.$refs.bankChoice_.getReadTest()
                    
                }
                
            },200)
            
            //this.getReadTest()
        },

        
        // 上一页
        prev(e){

            this.form_data.page = e
            this.getBankList_()
        },

        // 下一页
        next(e){

            this.form_data.page = e
            this.getBankList_()
        },

        // 点击页码
        changeNumber(e){

            this.form_data.page = e
            this.getBankList_()
        },

        // 前往指定页数
        changeSize(e){

            this.form_data.page = e
            this.getBankList_()
        },
        
        closeDialog(){

            this.read = {}
            this.getBankList_()
        }
    }
}
</script>

<style scoped>

.select{
    background: #409EFF !important;
    color: #ffffff !important;
    border-color: #409EFF !important;
}
.screen{
    width: 100%;
    box-sizing: border-box;

    text-align: left;
    border-radius: 6px;
    margin-top: 15px;
    background: #ffffff;
}
.content .screen >>> .is-disabled{
    height: 70px;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-right: 20px;
}
.content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}

</style>